import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header>
      <div className="flex flex-wrap items-center justify-end max-w-4xl mx-auto p-4">
        <nav className={`block flex items-center w-auto`}>
          {[
            {
              route: `/`,
              title: `Home`
            },
            {
              route: `/about`,
              title: `About`
            }
          ].map(link => (
            <Link
              className="block mt-4 ml-6 no-underline text-md text-bold"
              key={link.title}
              to={link.route}
            >
              {link.title.toUpperCase()}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
